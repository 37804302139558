import "../styles/mobile.less"
import "../styles/mobile-ext.less"
import './styles/Index2019.less'
import * as React from "react"
import * as ReactDOM from "react-dom"
import * as mockxhr from "mockxhr"
import * as qs from "query-string"
import * as util from "../util"
import * as _ from 'lodash'
import * as moment from 'moment'
import HeaderMenu from "./components/HeaderMenu"
import Footer from "./components/Footer"
import {Box} from "react-polymer-layout"
import {API, actions} from "../flux"
import {Carousel, Icon} from 'antd'
import MathJaxRenderer from "../components/MathJaxRenderer";

interface IState {
    slides: any[]
    headlines: any[]
    newsRank: any[]
    thesesRank: any[]
    daily: any[]
    adStore: any
    _timestamp: number
    showMenu: boolean
    periodicalList: IPeriodical[]
    theses: any[]
    science60s: any[]
    jobs: any[]
    careers: any[]
    careerPage: number
    careerSize: number
    employerNames: any[]
}

interface IPeriodical {
    checkname: string
    url: string
    logo?: string
}

const DEFAULT_FONT_SIZE = 16
const DEFAULT_WINDOW_WIDTH = 375
const RANK_COLOR = {
    0: '#3ec4f9',
    1: '#3ec4f9',
    2: '#3ec4f9',
}
let timer_news
let timer_theses
let speed = 50

export default class View extends React.Component<{}, IState> {
    readonly STORAGE_STATE = "mobile:Index"
    readonly STORAGE_SCROLLTOP = "mobile:Index:scrollTop"

    constructor(props) {
        super(props)
        this.state = {
            slides: [],
            headlines: [],  // 新闻
            daily: [],   // 科研圈日报
            newsRank: [],   // 新闻排行
            thesesRank: [],   // 论文排行
            periodicalList: [
                {
                    checkname: 'RNA Biology',
                    url: 'https://www.tandfonline.com/journals/krnb20?utm_source=banner&utm_medium=banner_ad&utm_campaign=JRH30616',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/11_%E6%B3%B0%E5%8B%92%E5%BC%97%E6%9C%97%E8%A5%BF%E6%96%AF%E5%87%BA%E7%89%88%E9%9B%86%E5%9B%A2_RNA%20Biology.png-transform2webp',
                },
                {
                    checkname: 'Frontiers of Computer Science',
                    url: 'https://journal.hep.com.cn/fcs/EN/current',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/15_%E9%AB%98%E7%AD%89%E6%95%99%E8%82%B2%E5%87%BA%E7%89%88%E7%A4%BE_Frontiers%20of%20Computer%20Science.png-transform2webp',
                },
                {
                    checkname: 'Bioactive Materials',
                    url: 'https://www.keaipublishing.com/en/journals/bioactive-materials/',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/13_%E7%A7%91%E7%88%B1_Bioactive%20Materials.png-transform2webp',
                },
                {
                    checkname: 'Frontiers in Immunology',
                    url: 'https://www.frontiersin.org/journals/immunology/about',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/9_Frontiers_Frontiers%20in%20Immunology%20.png-transform2webp',
                },
                {
                    checkname: 'IEEE Open',
                    url: 'https://open.ieee.org/',
                    logo: 'https://cdn.linkresearcher.com/index_ieee%20open.jpg-transform2webp',
                },
                {
                    checkname: 'Fundamental Research',
                    url: 'https://www.sciencedirect.com/journal/fundamental-research',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/14_%E7%A7%91%E7%88%B1_Fundamental%20Research.png-transform2webp',
                },
                {
                    checkname: 'Cell',
                    url: 'https://www.cell.com/cell/current',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/5_%E7%BB%86%E8%83%9E%E5%87%BA%E7%89%88%E7%A4%BE_Cell.png-transform2webp',
                },
                {
                    checkname: 'Scientific Reports',
                    url: 'https://www.nature.com/srep/',
                    logo: 'https://cdn.linkresearcher.com/index_scientific%20%20repotts.jpg-transform2webp',
                },
                {
                    checkname: 'Nature Communications',
                    url: 'https://www.nature.com/ncomms/',
                    logo: 'https://cdn.linkresearcher.com/index_nature%20communications.jpg-transform2webp',
                },
                {
                    checkname: 'JPhys Energy',
                    url: 'https://iopscience.iop.org/journal/2515-7655',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/18_%E8%8B%B1%E5%9B%BD%E7%89%A9%E7%90%86%E5%AD%A6%E4%BC%9A%E5%87%BA%E7%89%88%E7%A4%BE_JPhys%20Energy.png-transform2webp',
                },
                {
                    checkname: 'Critical Care',
                    url: 'https://ccforum.biomedcentral.com/',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/3-2_BMC_Critical%20Care_2.png-transform2webp',
                },
                {
                    checkname: 'Frontiers of Medicine',
                    url: 'https://journal.hep.com.cn/fmd/EN/2095-0217/home.shtml',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/16_%E9%AB%98%E7%AD%89%E6%95%99%E8%82%B2%E5%87%BA%E7%89%88%E7%A4%BE_Frontiers%20of%20Medicine.png-transform2webp',
                },
                {
                    checkname: 'Nanophotonics',
                    url: 'https://www.degruyter.com/journal/key/nanoph/html?utm_source=display&utm_medium=banner&utm_campaign=nanophotonics_linkresearcher_cn&utm_term=lw&utm_content=submissions',
                    logo: 'https://cdn.linkresearcher.com/home/article/journal_display/7_%E5%BE%B7%E5%8F%A4%E6%84%8F%E7%89%B9_Nanophotonics.png-transform2webp',
                },
                {
                    checkname: 'RSC',
                    url: 'https://www.rsc.org/journals-books-databases/about-journals/',
                    logo: 'https://cdn.linkresearcher.com/index_rsc.jpg-transform2webp',
                },
            ],   // 期刊数据
            theses: [{subject: ''}],  // 论文数据
            science60s: [],   // 科学60秒
            jobs: [],   // 招聘
            careers: [],  // 科研生涯
            careerPage: 0,   // 科研生涯页码
            careerSize: 33, // 科研生涯一页容量
            employerNames: [],   // 热门机构排行
            adStore: {
                MOBILE_HOME_1: {},
                MOBILE_HOME_2: {},
                MOBILE_HOME_3: {},
                MOBILE_JOB_1: {},
                MOBILE_JOB_2: {},
                MOBILE_JOB_3: {},
                MOBILE_JOB_4: {},
                MOBILE_JOB_5: {},
                HOME_SHORT_1: {},
                HOME_SHORT_2: {},
                HOME_SHORT_3: {},
                HOME_SHORT_4: {},
                HOME_SHORT_5: {},
            },  // 广告
            _timestamp: Date.now(),
            showMenu: false,
        }
        this.getMoreCareersData = this.getMoreCareersData.bind(this)
        this.clickPromotion = this.clickPromotion.bind(this)
    }

    componentDidMount() {
        // 新闻排行滚动
        let fa_news = document.querySelector('#information-father')
        let ch_news = document.querySelector('#information-child')

        timer_news = setInterval(() => {
            if (fa_news && ch_news) {
                if (fa_news.scrollTop >= ch_news.clientHeight / 2) {
                    fa_news.scrollTop = 0
                } else {
                    fa_news.scrollTop++
                }
            }
        }, speed)

        // 论文排行滚动
        let fa_theses = document.querySelector('#theses-father')
        let ch_theses = document.querySelector('#theses-child')

        timer_theses = setInterval(() => {
            if (fa_theses && ch_theses) {
                if (fa_theses.scrollTop >= ch_theses.clientHeight / 2) {
                    fa_theses.scrollTop = 0
                } else {
                    fa_theses.scrollTop++
                }
            }
        }, speed)

        // 动态调整根字体大小
        document.documentElement.style.fontSize = DEFAULT_FONT_SIZE / DEFAULT_WINDOW_WIDTH * document.documentElement.clientWidth + "px"

        // 记录滚动位置
        let body = document.querySelector("#root div.scrollBody") as HTMLElement
        if (!!body) {
            body.addEventListener("click", () => {
                localStorage.setItem(this.STORAGE_STATE, JSON.stringify(this.state))
                localStorage.setItem(this.STORAGE_SCROLLTOP, body.scrollTop + "")
                setTimeout(() => localStorage.setItem(this.STORAGE_STATE, JSON.stringify(this.state)), 10)
                setTimeout(() => localStorage.setItem(this.STORAGE_SCROLLTOP, body.scrollTop + ""), 10)
            })
            // 恢复页面数据
            let lastState = localStorage.getItem(this.STORAGE_STATE)
            if (typeof lastState === "string" && JSON.parse(lastState)._timestamp > Date.now() - 300000) {
                this.setState(JSON.parse(lastState), () => {
                    localStorage.removeItem(this.STORAGE_STATE)
                    setTimeout(() => {
                        if(window.performance.navigation.type ===2){
                            body.scrollTop = (localStorage.getItem(this.STORAGE_SCROLLTOP) || 0) as any
                        }
                        localStorage.removeItem(this.STORAGE_SCROLLTOP)
                    }, 500)
                })
                return
            }
        }

        // 自动加载更多
        // window.addEventListener("touchend", () => {
        //   let div = ReactDOM.findDOMNode(this.refs.more) as HTMLElement
        //   if (div) {
        //     let rect = div.getBoundingClientRect() as DOMRect
        //     if (rect.y < innerHeight && this.state.information.length < 18) this.loadMoreNews()
        //   }
        // })

        window.onpopstate = e => {
            location.href = "/"
        }
        window.history.pushState({}, "index", "#")
        window.history.forward()

        // 获取滚图数据
        mockxhr.POST(API.promotion.search("INDEX_SLIDE", "_", 99, 0), {}, resp => {
            this.setState({
                slides: resp.hits,
            })
        })

        // 获取新闻数据
        actions.api.article.search({
            size: 6, from: 0, type: "NEWS",
            request: {
                filters: {status: true},
                sort: [{"weight3": "DESC"}, {"onlineTime": "DESC"}],
            }
        }, res => {
            this.setState({headlines: res.hits})
        })

        // 科研圈日报
        actions.api.article.search({
            type: "NEWS", from: 0, size: 10,
            request: {
                filters: {status: true},
                query: '科研圈日报',
                sort: [{weight3: "DESC"}, {"onlineTime": "DESC"}],
            }
        }, res => this.setState({daily: res.hits}))

        // 新闻排行
        mockxhr.POST(`/api/articles/search/week?type=NEWS&from=0&size=15`, {
            filters: {},
            sort: [],
        }, resp => {
            this.setState({newsRank: resp.hits})
        })

        // 论文排行
        mockxhr.POST(`/api/articles/search/week?type=THESIS&from=0&size=15`, {
            filters: {},
            sort: [],
        }, resp => {
            this.setState({thesesRank: resp.hits})
        })

        // 论文数据
        actions.api.thesis.search({
            size: 10, from: 0, type: "DEFAULT",
            request: {
                filters: {},
                sort: [{"weight3": "DESC"}, {"onlineTime": "DESC"}],
            }
        }, res => this.setState({theses: res.hits}))

        // 科学60秒数据
        actions.api.article.search({
            type: "CAREER", from: 0, size: 10, df: "title",
            request: {
                query: "科学60秒",
                filters: {columns: "科学60秒", status: true},
                sort: [{"onlineTime": "DESC"}]
            }
        }, res => this.setState({science60s: res.hits}))

        // 科研生涯数据
        this.getMoreCareersData()

        // 广告数据
        mockxhr.POST(API.promotion.search("AD", "_", 99, 0), {}, res => {
            res.hits.forEach(item => this.state.adStore[item.type1] = item)
            this.setState({})
        })

        // 期刊数据
        // mockxhr.POST("/api/periodical/do?from=0&size=1000&type=SEARCH", {
        //   periodicalDao: {name: ""},
        //   sort: [],
        // }, resp => {
        //   let periodicalList = _.sortBy(_.filter(_.map(resp.hits.content, itm => {
        //     return {
        //       periodicalId: itm.id,
        //       checkname: itm.checkname,
        //       url: itm.url,
        //       logo: itm.logo,
        //       weight: itm.weight && itm.weight.split(';')[0],
        //       _weight: itm.weight && itm.weight.split(';')[1],
        //     }
        //   }), o => {
        //     return o.weight && o.weight > 0 && o._weight == 0
        //   }), k => {
        //     return k.weight
        //   })
        //   this.setState({ periodicalList })
        // })

        // 招聘数据
        actions.api.job.search({
            size: 9, from: 0, type: "DEFAULT",
            query: "", filters: {},
            sort: [{"weight2": "DESC"}, {onlineTime: "DESC"}],
        }, res => this.setState({jobs: res.hits}))

        // 热门招聘机构数据
        mockxhr.POST("/api/employers/search?" + qs.stringify({type: "SEARCH", from: 0, size: 3}), {
            filters: {}, query: "",
            sort: [{weight: "DESC"}, {certApproveTime: "DESC"}],
        }, res => this.setState({employerNames: res.hits}))

        // 鼠标移入停止滚动
        // window.addEventListener('mouseover', (e: any) => {
        //   if (['week-rank', 'list-wrapper', 'rank-list-wrapper', 'list-item', 'job-list-wrapper', 'rank-number', 'rank-text', 'rank-periodical'].includes(e.target.className)) {
        //     clearInterval(timer_job)
        //     clearInterval(timer_news)
        //     clearInterval(timer_theses)
        //   }
        // })

        // 鼠标移出继续滚动
        // window.addEventListener('mouseout', (e: any) => {
        //   if (['week-rank', 'list-wrapper', 'rank-list-wrapper', 'list-item', 'job-list-wrapper', 'rank-number', 'rank-text', 'rank-periodical'].includes(e.target.className)) {
        //     timer_job = setInterval(() => {
        //       if (fa_job && ch_job) {
        //         if (fa_job.scrollTop >= ch_job.clientHeight / 2) {
        //           fa_job.scrollTop = 0
        //         } else {
        //           fa_job.scrollTop ++
        //         }
        //       }
        //     }, speed)

        //     timer_news = setInterval(() => {
        //       if (fa_news && ch_news) {
        //         if (fa_news.scrollTop >= ch_news.clientHeight / 2) {
        //           fa_news.scrollTop = 0
        //         } else {
        //           fa_news.scrollTop ++
        //         }
        //       }
        //     }, speed)

        //     timer_theses = setInterval(() => {
        //       if (fa_theses && ch_theses) {
        //         if (fa_theses.scrollTop >= ch_theses.clientHeight / 2) {
        //           fa_theses.scrollTop = 0
        //         } else {
        //           fa_theses.scrollTop ++
        //         }
        //       }
        //     }, speed)
        //   }
        // })
    }

    componentWillUnmount() {
        clearInterval(timer_news)
        clearInterval(timer_theses)
    }

    headerBlock(name?, link?) {
        if (!name) return
        return (
            <div className="top">
                <span className="block-name" onClick={() => location.href = link}>{name}</span>
                <span className="read-more" onClick={() => location.href = link}>查看更多</span>
            </div>
        )
    }

    icon(title, link) {
        return (
            <div
                className="text-center"
                onClick={() => {
                    if (this.state.showMenu) {
                        this.setState({showMenu: false}, () => {
                            location.href = "/login?redirect=" + encodeURIComponent(location.href)
                        })
                    } else {
                        location.href = link
                        this.setState({showMenu: false})
                    }
                }}>
                <div className="text-primary" style={{fontSize: 17}}>
                    {title}
                </div>
            </div>
        )
    }

    newsItemWrapper(i) {
        const isCareer = i.type === "CAREER"
        let title = i.title
        if (title.includes('▶ 科学60秒：')) {
            title = title.split("|")[0]
        }
        title = title.replace('科研圈日报：', '').replace('▶ 科学60秒：', '')

        return (
            <div className="news-item-wrapper"
                 onClick={() => location.href = `/${i.type === 'CAREER' ? 'careers' : 'information'}/${i.id}`}>
                <div className="news-item-left">
                    <div className="news-item-title">{title}</div>
                    <div className="news-item-bottom">
                        <div
                            className="new-item-bottom-date">{isCareer ? '科研生涯' : '新闻'} &middot; {util.formatTs(i.onlineTime || i.updatedAt)}</div>
                        <div className="news-item-bottom-column">
                            <a className="text-primary"
                               href={"/searchall?" + qs.stringify({
                                   tab: i.type === "CAREER" ? "careers" : "information",
                                   "filters.columns": util.getInformationColumnByWeight(i.columns || [])
                               })}>
                                {util.getInformationColumnByWeight(i.columns || [])}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="news-item-right">
                    {util.imageMask(i.cover, 112, 75, () => 0, {}, true)}
                </div>
            </div>
        )
    }

    rankList(data, urlKey, id) {
        return (
            <div className='rank-list-wrapper' id={id}>
                {_.map(data, (item, index) => {
                    return (
                        <div
                            className='list-item'
                            key={item.id}
                            onClick={() => location.href = `/${urlKey}/${item.id}`}
                        >
              <span
                  className='rank-number'
                  style={{
                      background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                  }}
              >
                {index + 1}
              </span>

                            <span className='rank-text'><MathJaxRenderer text={item.title} /></span>

                            {urlKey === 'theses' &&
                                <div className='rank-periodical'>{item.journals && item.journals.join('; ')}</div>}
                        </div>
                    )
                })}
                <div style={{background: '#333', width: '21.5625rem', height: '2px', marginLeft: '-1.75rem'}}/>
                {_.map(data, (item, index) => {
                    return (
                        <div
                            className='list-item'
                            key={item.id}
                            onClick={() => {
                                window.open(`/${urlKey}/${item.id}`)
                            }}
                        >
              <span
                  className='rank-number'
                  style={{
                      background: `${index <= 2 ? RANK_COLOR[index] : '#989898'}`
                  }}
              >
                {index + 1}
              </span>

                            <span className='rank-text'>{item.title}</span>

                            {urlKey === 'theses' &&
                                <div className='rank-periodical'>{item.journals && item.journals.join('; ')}</div>}
                        </div>
                    )
                })}
            </div>
        )
    }

    thesesItemWrapper(i) {
        return (
            <div className="theses-item-wrapper" onClick={() => location.href = `/theses/${i.id}`}>
                <div className="theses-item-title">{i.title}</div>
                <div className="theses-item-bottom">
                    <div className="theses-item-date">论文 &middot; {util.formatTs(i.onlineTime || i.updatedAt)}</div>
                    <div className="theses-item-journals">
                        {_.map(i.journals, (itm, idx) => {
                            return (
                                <a className="theses-item-journal" href={`/searchall?${qs.stringify({
                                    tab: "theses",
                                    query: itm,
                                })}`}>{itm}{(idx + 1) < i.journals.length && '; '}</a>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    jobItemWrapper(i) {
        return (
            <div className="job-item-wrapper" onClick={() => location.href = `/jobs/${i.id}`}>
                <div className="job-item-left">
                    <div className="job-item-fullName">{i.fullEmployerName}</div>
                    <div className="job-item-sub">
                        <div className="job-item-date">招聘 &middot; {util.formatTs(i.onlineTime || i.updatedAt)}</div>
                        <div className="job-item-msg"><span
                            className="job-item-name">{i.name}</span> / {util.salaryText(i)}</div>
                    </div>
                </div>
                <div className="job-item-right">{util.imageCover(i.employerLogo, '3.125rem')}</div>
            </div>
        )
    }

    horizontalWrapper(content, className, offset = 200) {
        // let dom = document.querySelector(className)
        // let now_pos = dom ? (dom as any).scrollLeft : 0
        // let target_pos
        // let _speed = 15

        // const moveleft = () => {
        //   // let timer
        //   // target_pos = now_pos - offset

        //   // if (timer) {
        //   //   clearInterval(timer)
        //   // }

        //   // timer = setInterval(() => {
        //   //   if (now_pos >= target_pos) {
        //   //     now_pos = (dom as any).scrollLeft;
        //   //     (dom as any).scrollLeft = now_pos - 20
        //       (dom as any).scrollLeft = (dom as any).scrollLeft - offset
        //   //   } else {
        //   //     clearInterval(timer)
        //   //   }
        //   // }, _speed)
        // }

        // const moveRight = () => {
        //   // let timer
        //   // target_pos = now_pos + offset

        //   // if (timer) {
        //   //   clearInterval(timer)
        //   // }

        //   // timer = setInterval(() => {
        //   //   if (now_pos < target_pos) {
        //   //     now_pos = (dom as any).scrollLeft;
        //   //     (dom as any).scrollLeft = now_pos + 20
        //       (dom as any).scrollLeft = (dom as any).scrollLeft + offset
        //     // } else {
        //     //   clearInterval(timer)
        //     // }
        //   // }, _speed)
        // }

        return (
            <div className='horizontal-wrapper'>
                {/* <div className='move-left move' onClick={() => moveleft()}><Icon type="left" /></div> */}
                <div className='content'>
                    {content}
                </div>
                {/* <div className='move-right move' onClick={() => moveRight()}><Icon type="right" /></div> */}
            </div>
        )
    }

    getMoreCareersData() {
        let status = true

        if (!status) return
        status = false
        actions.api.article.search({
            type: "INFORMATION", from: this.state.careerPage * this.state.careerSize, size: this.state.careerSize,
            request: {
                filters: {status: true},
                sort: [{"weight2": "DESC"}, {"onlineTime": "DESC"}]
            },
        }, res => {
            this.setState({careers: this.state.careers.concat(res.hits)})
            status = true
        })
    }

    clickPromotion(id, link) {
        actions.clickPromotion(id, () => location.href = link)
    }

    render() {
        let {
            slides,
            headlines,
            daily,
            newsRank,
            theses,
            science60s,
            thesesRank,
            jobs,
            employerNames,
            careers,
            periodicalList
        } = this.state;
        careers = careers.filter(x => headlines.map(h => h.id).indexOf(x.id) < 0)
        let _theses = theses[0].subject.split(";").slice(0, 2)
        let thesesSubject = _.cloneDeep(_theses)
        // _theses.forEach(i => {
        //     if (i.length >= 4) {
        //         thesesSubject = _theses.slice(0, 1)
        //     }
        // })

        return (
            <div className='mobile-index2019-wrapper'>
                <HeaderMenu
                    selectMenu="index"
                    getShowStatus={e => this.setState({showMenu: e})}
                    height={91}
                    tabs={
                        <Box justified style={{background: "#fff", padding: 15}}>
                            {this.icon("论文", "/theses")}
                            {this.icon("期刊", "/periodical")}
                            {this.icon("新闻", "/information")}
                            {this.icon("招聘", "/search")}
                            {this.icon("生涯", "/careers")}
                            {/* {this.icon("活动", "/meetings")} */}
                        </Box>
                    }>
                    <div className="content-wrapper">
                        {/* 轮播图 */}
                        <div className="carousel-wrapper">
                            <Carousel autoplay ref='carousel'>
                                {_.map(slides, i => {
                                    return (
                                        <a className='slider-item' href={i.link} title={i.title}
                                           onClick={() => actions.clickPromotion(i.id, () => 0, 'mobile')}>
                                            {util.imageMask(i.image, 'auto', '13.125rem', () => 0, {}, false)}
                                            <div className="title">{i.title}</div>
                                        </a>
                                    )
                                })}
                            </Carousel>
                        </div>

                        {/* 新闻 */}
                        <div className="headlines-wrapper main-wrapper">
                            {this.headerBlock()}

                            {_.map(headlines, i => this.newsItemWrapper(i))}

                            {this.horizontalWrapper(
                                <div className='horizontal-content daily' style={{height: '16.875rem'}}>
                                    {_.map(daily, i => {
                                        return (
                                            <div className='horizontal-item daily-item shadow-box'
                                                 onClick={() => location.href = `/information/${i.id}`}>
                                                <div className="daily-icon"><img src='/assets/icons/book-1.svg'/>科研圈日报
                                                </div>
                                                <div
                                                    className='daily-date'>{moment(i.onlineTime).format('YYYY/MM/DD')}</div>
                                                <div
                                                    className="daily-img">{util.imageMask(i.cover, '17.8125rem', '10rem', () => 0, {
                                                    borderBottomLeftRadius: '0px',
                                                    borderBottomRightRadius: '0px'
                                                }, false, {background: "rgba(51,51,51,0.2)"})}</div>
                                                <div className="daily-title">{i.title.replace("科研圈日报：", "")}</div>
                                                <div className="daily-summary">
                                                    {i.summary && _.map(i.summary.split('；').slice(0, 2), i => {
                                                        return (
                                                            <div
                                                                className='daily-summary-item purple-hover'>· {i.replace("更多：", "")}</div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>, '.daily'
                            )}
                            <div className='week-rank shadow-box'>
                                <div className='title'>
                                    <img className='icon' src='/assets/icons/crown-red.svg'/>
                                    一周新闻排行
                                </div>

                                <div style={{maxHeight: '220px'}} className='list-wrapper' id='information-father'>
                                    {this.rankList(newsRank, 'information', 'information-child')}
                                </div>
                            </div>
                        </div>

                        {/* 移动端广告1 */}
                        {this.state.adStore.MOBILE_HOME_1.id && <div style={{
                            width: '21.5625rem',
                            margin: '0 auto',
                            marginBottom: '0.625rem',
                            background: "#f4f4f4"
                        }}>
                            <img src={this.state.adStore.MOBILE_HOME_1.image}
                                 style={{width: "100%", verticalAlign: "top"}}
                                 onClick={() => this.clickPromotion(
                                     this.state.adStore.MOBILE_HOME_1.id,
                                     this.state.adStore.MOBILE_HOME_1.link,
                                 )}/>
                        </div>}

                        {/* 论文 */}
                        <div className="theses-wrapper main-wrapper">
                            {this.headerBlock('论文', '/theses')}

                            {(periodicalList.length > 0) && this.horizontalWrapper(
                                <div className='horizontal-content periodical'
                                     style={{height: '143px', marginBottom: '46px'}}>
                                    {_.map(periodicalList, (itm, idx) => {
                                        return (
                                            <div className='horizontal-item periodical-item'>
                                                <div
                                                    className='periodical-block'
                                                    style={{
                                                        backgroundImage: `url(${itm.logo})`
                                                    }}
                                                    onClick={() => window.open(itm.url)}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>, '.periodical'
                            )}

                            {theses[0] && <div
                                className='first-theses'
                                onClick={() => location.href = `/theses/${theses[0].id}`}
                            >
                                <div className='first-theses-cover'>
                                    {util.imageMask(theses[0].cover, 'auto', '9.0625rem', () => 0, {
                                        borderBottomLeftRadius: '0px',
                                        borderBottomRightRadius: '0px'
                                    }, false, {background: "rgba(51,51,51,0.2)"})}
                                    <div className='first-theses-title'>{theses[0].title}</div>
                                </div>
                                <div className='first-theses-bottom'>
                  <span className='periodical-area'>
                    <span className='periodical'>{theses[0].journals && theses[0].journals.join('; ')}</span>
                  </span>

                                    <span className='subject'>
                    {_.map(thesesSubject, (itm, idx) => {
                        return <a className='subject-item'
                                  href={`/searchall?tab=theses&filters.subject=${encodeURIComponent(itm)}`}>{itm}{((idx + 1) < thesesSubject.length) && ','}</a>
                    })}
                  </span>
                                </div>
                            </div>}

                            {_.map(theses.slice(1), i => this.thesesItemWrapper(i))}


                            {this.horizontalWrapper(
                                <div className='horizontal-content science'
                                     style={{height: '12.5rem', margin: '0.9375rem 0'}}>
                                    {_.map(science60s, i => {
                                        return (
                                            <div className='horizontal-item science-item shadow-box'
                                                 onClick={() => location.href = `/careers/${i.id}`}>
                                                <div className='science-top'>
                                                    <div className="science-icon"><img
                                                        src='/assets/icons/music-red.svg'/>音频导读·科学60秒
                                                    </div>
                                                    <div
                                                        className="science-img">{util.imageMask(i.cover, '17.8125rem', '10rem', () => 0, {
                                                        borderBottomLeftRadius: '0px',
                                                        borderBottomRightRadius: '0px'
                                                    }, false, {background: "rgba(51,51,51,0.2)"})}</div>
                                                    <div
                                                        className="science-title">{i.title.split('|')[0].split('：')[1]}</div>
                                                </div>
                                                <div className='science-bottom'>
                          <span className='science-bottom-play'>
                            <Icon type="play-circle"/>
                          </span>
                                                    <span className='science-bottom-time'>
                            {i.title.split('|')[1]}
                          </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>, '.science'
                            )}

                            <div className='week-rank shadow-box'>
                                <div className='title'>
                                    <img className='icon' src='/assets/icons/crown-red.svg'/>
                                    一周论文排行
                                </div>

                                <div style={{maxHeight: '220px'}} className='list-wrapper' id='theses-father'>
                                    {this.rankList(thesesRank, 'theses', 'theses-child')}
                                </div>
                            </div>
                        </div>

                        {/* 移动端广告2 */}
                        {this.state.adStore.MOBILE_HOME_2.id && <div style={{
                            width: '21.5625rem',
                            margin: '0 auto',
                            marginBottom: '0.625rem',
                            background: "#f4f4f4"
                        }}>
                            <img src={this.state.adStore.MOBILE_HOME_2.image}
                                 style={{width: "100%", verticalAlign: "top"}}
                                 onClick={() => this.clickPromotion(
                                     this.state.adStore.MOBILE_HOME_2.id,
                                     this.state.adStore.MOBILE_HOME_2.link,
                                 )}/>
                        </div>}

                        {/* 招聘 */}
                        <div className="job-wrapper main-wrapper">
                            {this.headerBlock('招聘', '/search')}

                            {jobs[0] && <div
                                className='first-job mask'
                                style={{backgroundImage: 'url(/assets/images/mobile-job-bg-1.jpg)'}}
                                onClick={() => location.href = `/jobs/${jobs[0].id}`}
                            >
                                <div className="first-job-left">{util.imageCover(jobs[0].employerLogo, '5rem')}</div>
                                <div className="first-job-right">
                                    <div className="first-job-fullName">{jobs[0].fullEmployerName}</div>
                                    <div className="sub"><span
                                        className="name">{jobs[0].name}</span> / {util.salaryText(jobs[0])}</div>
                                </div>
                            </div>}

                            {_.map(jobs.slice(1), i => this.jobItemWrapper(i))}

                            <div className='week-rank shadow-box'>
                                <div className='title'>
                                    <img className='icon' src='/assets/icons/fire.svg'/>
                                    热门机构
                                </div>

                                {employerNames.length === 3 &&
                                    <div style={{maxHeight: '12.75rem'}} className='list-wrapper'>
                                        <div className="hot-employer">
                                            <div className="hot-employer-left hot-employer-block mask"
                                                 onClick={() => location.href = `/searchall?tab=jobs&query=${encodeURIComponent(employerNames[0].name)}`}
                                                 style={{backgroundImage: 'url(/assets/images/mobile-job-bg-1.jpg)'}}>
                                                {util.imageCover(employerNames[0].logo, '2.25rem', {
                                                    position: 'absolute',
                                                    top: '1rem',
                                                    left: '1rem'
                                                })}
                                                <div className="hot-employer-text"
                                                     style={{top: '65%'}}>{employerNames[0].name}</div>
                                            </div>
                                            <div className="hot-employer-right">
                                                <div className="hot-employer-right-block hot-employer-block mask"
                                                     onClick={() => location.href = `/searchall?tab=jobs&query=${encodeURIComponent(employerNames[1].name)}`}
                                                     style={{
                                                         backgroundImage: 'url(/assets/images/mobile-job-bg-2.jpg)',
                                                         marginBottom: '0.625rem'
                                                     }}>
                                                    {util.imageCover(employerNames[1].logo, '2.25rem', {
                                                        position: 'absolute',
                                                        top: '0.8125rem',
                                                        left: '0.75rem'
                                                    })}
                                                    <div className="hot-employer-text"
                                                         style={{top: '40%'}}>{employerNames[1].name.slice(0, 18)}</div>
                                                </div>
                                                <div className="hot-employer-right-block hot-employer-block mask"
                                                     onClick={() => location.href = `/searchall?tab=jobs&query=${encodeURIComponent(employerNames[2].name)}`}
                                                     style={{backgroundImage: 'url(/assets/images/mobile-job-bg-3.jpg)'}}>
                                                    {util.imageCover(employerNames[2].logo, '2.25rem', {
                                                        position: 'absolute',
                                                        top: '0.8125rem',
                                                        left: '0.75rem'
                                                    })}
                                                    <div className="hot-employer-text"
                                                         style={{top: '40%'}}>{employerNames[2].name.slice(0, 18)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>

                        {/* 移动端广告3 */}
                        {this.state.adStore.MOBILE_HOME_3.id && <div style={{
                            width: '21.5625rem',
                            margin: '0 auto',
                            marginBottom: '0.625rem',
                            background: "#f4f4f4"
                        }}>
                            <img src={this.state.adStore.MOBILE_HOME_3.image}
                                 style={{width: "100%", verticalAlign: "top"}}
                                 onClick={() => this.clickPromotion(
                                     this.state.adStore.MOBILE_HOME_3.id,
                                     this.state.adStore.MOBILE_HOME_3.link,
                                 )}/>
                        </div>}

                        {/* 科研生涯 */}
                        <div className="careers-wrapper main-wrapper" style={{marginBottom: 0}}>
                            {this.headerBlock('科研生涯', '/careers')}

                            {_.map(careers.slice(0, 20 * (this.state.careerPage + 1)), i => this.newsItemWrapper(i))}

                            {/* 加载更多 */}
                            <div className='more' onClick={() => {
                                this.setState({careerPage: this.state.careerPage + 1}, () => {
                                    this.getMoreCareersData()
                                })
                            }}>
                                加载更多
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </HeaderMenu>
            </div>
        )
    }
}

ReactDOM.render(<View/>, document.body.firstChild as any)